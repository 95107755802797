import moment from "moment";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { addUpdateConsultant } from "../api/consultants-api";

import { useSnackbar } from "react-simple-snackbar";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { mapResponseToDTO } from "../api/mapper";
import { nanoid } from "nanoid";
import { AddProject, getAllProjects, getAllProjectsByUuid } from "../api/project-api";
import { Accordion } from "flowbite-react";
import Moment from "react-moment";
import { Md5 } from "ts-md5";
export default function ProjectDetails(props: { profile: any }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [projects, setProjects] = useState<any>([]);
    const [projectMaster, setProjectMaster] = useState<any>();
    const [data, setData] = useState<any>([]);
    const schema = yup.object({
        // e_regular: yup.number().required("*required"),
    });

    let values: any = {};
    // if (props.profile.unstructured) {
    //     values = JSON.parse(props.profile.unstructured)?.paystubAnnexure;
    //     // console.log(values);
    // }

    const [openSnackbar, closeSnackbar] = useSnackbar();

    // if (props.profile) {
    // }

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {},
        values,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        reset,
        trigger,
        setValue,
        getValues,
        getFieldState,
        formState: { errors },
    } = methods;

    useEffect(() => {
        // setStartDate(moment(props.profile.start_dt).toDate());
        // setEndDate(moment(props.profile.end_dt).toDate());

        // if (props.profile) {
        //     props.profile.start_dt = moment(props.profile.start_dt).format("YYYY-MM-DD HH:mm:ss");
        //     props.profile.end_dt = moment(props.profile.end_dt).format("YYYY-MM-DD HH:mm:ss");
        // }
        getAllProjects().then((allProjects: any[]) => {
            setProjects(allProjects);
        });
    }, []);

    useEffect(() => {
        // you can do async server request and fill up form

        getAllProjectsByUuid(props.profile.uuid).then((projects: any) => {
            setData(projects);
        });
    }, [reset]);
    const onEdit = () => {
        let activeProject = data.find((e) => e.active === 1);
        if (activeProject) {
            const project = {
                ...activeProject,
                start_dt: new Date(activeProject.start_dt),
                end_dt: new Date(activeProject.end_dt),
            };
            setStartDate(moment(project.start_dt).toDate());
            setEndDate(moment(project.end_dt).toDate());
            reset(project);
            setProjectMaster(project.name);
        }
    };

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        const profile = mapResponseToDTO<typeof props.profile, typeof data>(data);

        // FIXME;
        // profile.start_dt = moment(props.profile.start_dt).format("YYYY-MM-DD HH:mm:ss");
        // profile.end_dt = moment(props.profile.end_dt).format("YYYY-MM-DD HH:mm:ss");

        // Updated Start Date and End Date
        data.start_dt = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
        data.end_dt = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
        const project = {
            uuid: profile.uuid,
            pid: Md5.hashStr(data.project),
            active: 1,
            start_dt: data.start_dt,
            end_dt: data.end_dt,
            billing_period: data.billing_period,
            rate: data.rate,
            account: projectMaster.account,
            role: projectMaster.role,
        };
        // var finalObject = Object.assign(props.profile, data

        AddProject(project).then((result: any) => {
            openSnackbar(result?.error ? result?.error?.sqlMessage : "Project details updated.");
        });
        // project table will be saved
    };

    return (
        <>
            <Accordion>
                {/* <Accordion.Panel>
                    <Accordion.Title>Current Project</Accordion.Title>
                    <Accordion.Content>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="space-y-6">
                                    <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                            <div className="md:col-span-1">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Project Details</h3>
                                                <p className="mt-1 text-xs text-gray-500">Client, Account & Project etc.</p>
                                            </div>
                                            <div className="mt-5 md:mt-0 md:col-span-2">
                                                <div className="grid grid-cols-4 gap-6">
                                                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label htmlFor="project" className="block text-sm font-medium text-gray-700">
                                                            Project
                                                        </label>
                                                        <select {...register("project", { required: false, onChange: (e) => setProjectMaster(e.target.value) })} id="project" multiple={false} size={1} name="project" className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                            <option value={""}>select</option>
                                                            {projects.map((p: any, pIdx: any) => (
                                                                <option value={p} key={nanoid()}>
                                                                    {p.clientName}-{p.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="space-y-6">
                                        <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                                <div className="md:col-span-1">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Project Duration</h3>
                                                    <p className="mt-1 text-xs text-gray-500">Billing Type, Dates etc.</p>
                                                </div>

                                                <div className="mt-5 md:mt-0 md:col-span-2">
                                                    <div className="grid grid-cols-4 gap-6">
                                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                                Start Date
                                                            </label>

                                                            <DatePicker
                                                                {...register("start_dt", {
                                                                    required: false,
                                                                })}
                                                                // value={props.profile.start_dt}
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={startDate}
                                                                onChange={(date) => {
                                                                    console.warn(date);
                                                                    setStartDate(date);
                                                                }}
                                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                                End Date
                                                            </label>

                                                            <DatePicker
                                                                {...register("end_dt", {
                                                                    required: false,
                                                                })}
                                                                // value={props.profile.end_dt}
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={endDate}
                                                                onChange={(date) => {
                                                                    console.warn(date);
                                                                    setEndDate(date);
                                                                }}
                                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                            <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                                                Billing Period
                                                            </label>
                                                            <select
                                                                // value={props.profile.billing_period}
                                                                id="billing_period"
                                                                // name="status"
                                                                autoComplete="billing_period"
                                                                defaultValue={""}
                                                                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                {...register("billing_period", {
                                                                    required: false,
                                                                    // onChange: (e) => setFil({ ...props.profile.billing_period, employee: e.target.value })
                                                                })}
                                                                onChange={(e) => {
                                                                    props.profile.billing_period = e.target.value;
                                                                }}
                                                            >
                                                                <option value="Weekly">Weekly</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Monthly Itemized">Monthly Itemized</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="space-y-6">
                                        <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                                <div className="md:col-span-1">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Project Commercials</h3>
                                                    <p className="mt-1 text-xs text-gray-500">Billing Rate etc.</p>
                                                </div>

                                                <div className="mt-5 md:mt-0 md:col-span-2">
                                                    <div className="grid grid-cols-4 gap-6">
                                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                            <label htmlFor="rate" className="block text-sm font-medium text-gray-700">
                                                                Project Rate (per hour)
                                                            </label>
                                                            <input
                                                                {...register("rate", {
                                                                    required: false,
                                                                })}
                                                                // value={props.profile.rate}
                                                                type="text"
                                                                name="rate"
                                                                id="rate"
                                                                autoComplete="rate"
                                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-end pb-10">
                                        <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Cancel
                                        </button>
                                        <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </Accordion.Content>
                </Accordion.Panel> */}
                <Accordion.Panel>
                    <Accordion.Title>Projects</Accordion.Title>
                    <Accordion.Content>
                        <div className="pb-16 space-y-6">
                            <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className=" md:mt-0 md:col-span-2">
                                        <div className="overflow-hidden bg-white sm:rounded-lg">
                                            <ul role="list" className="border-gray-200 divide-y divide-gray-200 ">
                                                {data &&
                                                    data.map((item) => (
                                                        <li key={item.course} className="flex py-4">
                                                            {/* <img className="w-10 h-10 rounded-full" src={person.image} alt="" /> */}
                                                            <div className="ml-3">
                                                                <p className="text-xl font-bold text-gray-900 ">
                                                                    {item.name}
                                                                    &nbsp; &nbsp;{item.active === 1 && <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Active</span>}
                                                                </p>

                                                                <p className="text-sm font-medium text-gray-900">
                                                                    <span className="text-sm text-gray-500">{item.account}</span>
                                                                </p>

                                                                <p className="text-sm text-gray-500">
                                                                    <Moment format="YYYY/MM/DD">{item?.start_dt}</Moment> - <Moment format="YYYY/MM/DD">{item?.end_dt}</Moment>
                                                                </p>
                                                            </div>
                                                            {/* {item.active === 1 && (
                                                                <div>
                                                                    <a className="text-indigo-600 hover:text-indigo-900" onClick={onEdit}>
                                                                        edit
                                                                    </a>
                                                                </div>
                                                            )} */}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
        </>
    );
}
