import { PayrollModel } from "../model";
import moment from "moment";
export async function listOfPayroll() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/payroll?_where=(uuid,ne,null)&_sort=check_date");
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getFilteredPayroll(filters: any) {
    console.log(filters);

    let _q = "select distinct * from payroll where 1=1 "; // Start with a safe base query

    if (filters.checkDate) {
        _q += " and year(check_date) = " + filters.checkDate.getFullYear();
        _q += " and month(check_date) = " + (filters.checkDate.getMonth() + 1);
    } else {
        console.error("checkDate is null or undefined");
        // Handle the situation where checkDate is null, maybe return or throw an error
    }

    if (filters.employee) {
        _q += " and uuid = '" + filters.employee + "' ";
    }

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["payroll"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getFilteredPayrollSummary(filters: any) {
    console.log(filters);

    let _q = "select distinct *, sum(net_pay) as _s_np, sum(e_total) as _s_e_total, sum(d_total) as _s_d_total, sum(t_total) as _s_t_total, sum(hrs) as _s_hrs  from payroll where net_pay > 0 ";
    
    if (filters && filters.checkDate) {
        _q += "  and month(check_date) = " + (filters.checkDate.getMonth() + 1) + "";
        _q += "  and year(check_date) = " + filters.checkDate.getFullYear() + "";
    }

    if (filters.employee) {
        _q += " and  uuid = '" + filters.employee + "' ";
    }

    if (filters.groupedUUID) {
        _q += " group by uuid,_d_start ,_d_end ";
    } else {
        _q += " order by uuid ";
    }

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["payroll"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function saveBulkPayroll(list: any) {
    console.log(list);
    console.log(list.filter((entry) => entry.hrs > 0));

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/payroll/bulk", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(list.filter((entry) => entry.selected)),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function savePayroll(list: PayrollModel) {
    try {
        
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/payroll", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(list),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getFortnightPayroll(filters: any) {
    console.log(filters);

    let date =moment(filters.checkDate)
    let _q = "select  * from payroll where  "; // Start with a safe base query

    if (filters.checkDate) {
        _q += "  year(_d_start) = " + date.year();
        _q += " and month(_d_start) = " + (date.month() + 1);
        _q += " and DAY(_d_start) = " + date.date();
    } else {
        console.error("checkDate is null or undefined");
        // Handle the situation where checkDate is null, maybe return or throw an error
    }
    

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["payroll"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}