import { useState ,useEffect} from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import ProjectDrawer from "./projectDrawer";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function ClientTable(props:{ clients: any[] }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const onOpen = () => {
        setSidebarOpen(true);
    };
    const [clients, setClients] = useState(props.clients);
    const onClose = () => {
        setSidebarOpen(false);
    };
    useEffect(() => {
        setClients(props.clients)
    }, [props.clients])
    
    

    return (
        <>
            {/* <ProjectDrawer open={sidebarOpen} onClose={onClose} /> */}

            <div className="min-h-full">
                {/* Static sidebar for desktop */}
                <main className="flex-1">
                    {/* Page title & actions */}

                    {/* Projects list (only on smallest breakpoint) */}
                    <div className="mt-0 sm:hidden">
                        <div className="px-4 sm:px-6">
                            <h2 className="text-xs font-medium tracking-wide text-gray-500 uppercase">Projects</h2>
                        </div>
                        <ul role="list" className="mt-3 border-t border-gray-200 divide-y divide-gray-100">
                            { clients && clients.map((client:any) => (
                                <li key={client.id}>
                                    <a href="#" className="flex items-center justify-between px-4 py-4 group hover:bg-gray-50 sm:px-6">
                                        <span className="flex items-center space-x-3 truncate">
                                            <span className={classNames(client.bgColorClass, "w-2.5 h-2.5 flex-shrink-0 rounded-full")} aria-hidden="true" />
                                            <span className="text-sm font-medium leading-6 truncate">
                                                {client.name} 
                                            </span>
                                        </span>
                                        <ChevronRightIcon className="w-5 h-5 ml-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Projects table (small breakpoint and up) */}
                    <div className="hidden mt-8 sm:block">
                        <div className="inline-block min-w-full align-middle border-b border-gray-200">
                            <table className="min-w-full">
                                <thead>
                                    <tr className="border-t border-gray-200">
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            <span className="lg:pl-2">Client Name</span>
                                        </th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">Address</th>
                                        <th className="py-3 pr-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase border-b border-gray-200 bg-gray-50" />
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-100">
                                    {clients.map((client:any) => (
                                        <tr key={client.id}>
                                            <td className="w-full px-6 py-3 text-sm font-medium text-gray-900 max-w-0 whitespace-nowrap">
                                                <div className="flex items-center space-x-3 lg:pl-2">
                                                    <div className="flex-shrink-0 w-2.5 h-2.5 rounded-full" aria-hidden="true" />
                                                    <a href="#" className="truncate hover:text-gray-600">
                                                        <span>
                                                            {client.name} 
                                                        </span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td className="px-6 py-3 text-sm font-medium text-gray-500">
                                            <div className="flex items-center space-x-3 lg:pl-2">
                                                    <div className="flex-shrink-0 w-2.5 h-2.5 rounded-full" aria-hidden="true" />
                                                        <span>
                                                            {client.address} 
                                                        </span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-3 text-sm font-medium text-right whitespace-nowrap">
                                                <a href={"/projects/"+client.id} className="text-indigo-600 hover:text-indigo-900" onClick={onOpen}>
                                                    Details
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
