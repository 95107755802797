import moment from "moment";
import { AdjustRateParams, InvoiceRequestParams } from "../model";

export async function getFinanceAgregatedDetails(filters: any) {
    //FIXME
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "select _d, count(_d), f.hrs * p.rate as bill  , sum(payment), sum(verified), sum(hrs) from finance as f left join project as p on f.uuid = p.uuid and p.active = true group by _d",
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getFinanceDetails(filters: any) {
    //FIXME - Date, User, Project

    let _q = "select *, p.rate * f.hrs as bill from finance as f left join consultant as c on f.uuid = c.uuid left join project as p on p.uuid = c.uuid and '"+filters.date+"' between p.start_dt and p.end_dt  where 1=1 ";
    if (filters.date) {
        _q += "and _d = " + "'" + filters.date + "'";
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getFinanceReportData(filters: any) {
    // console.log(filters);
    try {
        let _q =
            "SELECT  SUM(IFNULL(finance.payment, 0)) as payment, month(_d) as m, _d, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%m') AS month, sum(project.rate * finance.hrs) as bill, sum(hrs) as hrs, invoice_no, (COALESCE(sum(project.rate * finance.hrs),0)-COALESCE(sum(finance.payment),0)) as diff, (COALESCE((fixed_payroll),0)-COALESCE(sum(finance.payment),0)) as fixed_diff, finance.uuid ,project_master.name,consultant.name,project.rate as prate, project.rate as rate from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid  AND _d BETWEEN project.start_dt AND project.end_dt LEFT JOIN project_master ON project.pid = project_master.pid  ";
        _q += " where 1=1 ";
        if (filters && filters.checkDate) {
            _q += "  and month(_d) = " + (filters.checkDate.getMonth() + 1) + "";
            _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
        }

        if (filters && filters.employee) {
            _q += "  and finance.uuid = '" + filters.employee + "' ";
        }

        _q += " group by uuid, consultant.name, month1, year ";

        _q += " order by consultant.name asc, _d asc ";

        console.log("1", _q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getFinanceReportDataByIkat(filters: any) {
    // console.log(filters);
    try {
        let _q =
            "SELECT  SUM(IFNULL(finance.payment, 0)) as payment, month(_d) as m, _d, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%m') AS month, _ts_bill, DATE_FORMAT(_ts_bill, '%Y-%m') AS billMonth, _ts_invoice_due, DATE_FORMAT(_ts_invoice_due, '%Y-%m') AS invoiceDueMonth, sum(project.rate * finance.hrs) as bill, sum(hrs) as hrs, invoice_no, (COALESCE(sum(project.rate * finance.hrs),0)-COALESCE(sum(finance.payment),0)) as diff, (COALESCE((fixed_payroll),0)-COALESCE(sum(finance.payment),0)) as fixed_diff, (sum(hrs) - COALESCE(sum(payment),0)/project.rate) as balance_hrs, finance.uuid ,project_master.name,consultant.name,project.rate as prate, project.rate as rate from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid AND _d BETWEEN project.start_dt AND project.end_dt  LEFT JOIN project_master ON project.pid = project_master.pid ";
        _q += " where 1=1 and project_master.name= 'IKAT' ";
        if (filters && filters.checkDate) {
            _q += "  and month(_d) = " + (filters.checkDate.getMonth() + 1) + "";
            _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
        }

        if (filters && filters.employee) {
            _q += "  and finance.uuid = '" + filters.employee + "' ";
        }

        _q += " group by uuid, consultant.name,  project_master.pid, month1, year ";

        _q += " order by consultant.name asc ";

        console.log(_q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}



export async function getInvoicesData(filters: any) {
    console.log(filters);
    try {
        let _q =
            "SELECT  SUM(IFNULL(finance.payment, 0)) as payment, project.billing_period,count(finance.hrs) as _punched, consultant.fixed_payroll,  month(_d) as m, _d, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%m') AS month,  sum(project.rate * finance.hrs) as bill, sum(hrs) as hrs, invoice_no, (COALESCE(sum(project.rate * finance.hrs),0)-COALESCE(sum(finance.payment),0)) as diff, (COALESCE((fixed_payroll),0)-COALESCE(sum(finance.payment),0)) as fixed_diff, finance.uuid ,project_master.name as pid,consultant.name,project.rate as prate, project.rate as rate from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid   AND _d BETWEEN project.start_dt AND project.end_dt LEFT JOIN project_master ON project.pid = project_master.pid ";
        _q += " where 1=1 ";
        if (filters && filters.checkDate) {
            _q += "  and month(_d) = " + (filters.checkDate.getMonth() + 1) + "";
            _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
        }

        if (filters && filters.employee) {
            _q += "  and finance.uuid = '" + filters.employee + "' ";
        } // FIXME

        _q += " group by uuid, consultant.name, month1, pid, invoice_no, year ";

        console.log(_q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getFinanceReportDataByMonth(filters: any) {
    // console.log(filters);
    try {
        let _q =
            "SELECT  SUM(IFNULL(finance.payment, 0)) as payment, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%m') AS month,  sum(project.rate * finance.hrs) as bill, sum(hrs) as hrs,(COALESCE(sum( project.rate * finance.hrs),0)-COALESCE(sum(finance.payment),0)) as diff, (COALESCE((fixed_payroll),0)-COALESCE(sum(finance.payment),0)) as fixed_diff, finance.uuid ,project_master.name,consultant.name,project.rate from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid AND _d BETWEEN project.start_dt AND project.end_dt LEFT JOIN project_master ON project.pid = project_master.pid ";
        _q += " where 1=1 ";

        if (filters && filters.checkDate) {
            _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
        }

        _q += " group by month1, year order by _d";

        // console.log(_q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getOutstandingPayments(filters: any) {
    // console.log(filters);
    try {
        let _q =
            "SELECT  SUM(IFNULL(finance.payment, 0)) as payment, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%m') AS month,sum( project.rate * finance.hrs) as bill, sum(hrs) as hrs,(COALESCE(sum(project.rate * finance.hrs),0)-COALESCE(sum(finance.payment),0)) as diff, (COALESCE((fixed_payroll),0)-COALESCE(sum(finance.payment),0)) as fixed_diff, finance.uuid ,project_master.name,consultant.name,project.rate from finance LEFT JOIN consultant ON consultant.uuid =finance.uuid LEFT JOIN project ON consultant.uuid = project.uuid AND _d BETWEEN project.start_dt AND project.end_dt  LEFT JOIN project_master ON project.pid = project_master.pid ";
        _q += " where 1=1 ";

        if (filters && filters.checkDate) {
            _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
        }

        // _q += " group by month1 ";

        _q += " order by _d ";

        // console.log(_q);

        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

// export async function getfinances(request: any) {
//     let query = "";
//     if (this.filteredConsultant) {
//         query = "_d = " + '"' + request + '" and uid = ' + this.filteredConsultant.id;
//     } else if (this.filteredProject) {
//         query = "_d = " + '"' + request + '" and pid = ' + this.filteredProject.project;
//     } else {
//         query = "_d = " + '"' + request + '"';
//     }
//     this.financeService.getFinances(query).subscribe((result: any) => {
//         this.isdateeventclicked = true;
//         this.dialogHeader = "Payment Details";
//         this.finances = result;
//     });
// }

export async function saveFinanceRecord(financeObj: any) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/finance", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(financeObj),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

// function is not used
export async function getBillingDetails(filters: any) {
    // console.log(filters);

    let _q = "select distinct uuid, _d, month(_d) as _m, sum(pro) as _bill, sum(payment) as _payment, sum(hrs) as _hrs  from finance as f left join project as p on f.uuid = p.uuid where year(_d) = " + filters.checkDate.getFullYear() + " and month(_d) = " + (filters.checkDate.getMonth() + 1) + "  ";
    if (filters.employee) {
        _q += " and uuid = '" + filters.employee + "' ";
    } else {
        _q += " and uuid is not null and uuid <> '' ";
    }
    _q += " group by month(_d), uuid  ";
    // console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function adjustRate(params: AdjustRateParams) {
    // console.log(params);

    let _q = "UPDATE finance SET rate = " + Number(params.newRate) + " where 1=1 ";

    if (params.uuid) {
        _q += " and uuid = '" + params.uuid + "' ";
    }

    if (params.checkDate) {
        _q += " and month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear();
    }

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function generateInvoice(params: InvoiceRequestParams) {
    // console.log(params);

    //add(1, "M") FIXME, params.checkDate
    let _q = "UPDATE finance SET `invoice_no` = '" + params.invoiceNo + "' , _ts_invoice = '" + moment().format("YYYY-MM-DD") + "', _ts_invoice_due = '" + moment().add(1, "M").format("YYYY-MM-DD") + "' where 1=1 ";

    if (params.uuid) {
        _q += " and uuid = '" + params.uuid + "' ";
    }

    if (params.checkDate) {
        _q += " and month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear();
    }

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function generateWeeklyInvoice(params: InvoiceRequestParams) {
    // console.log(params);

    let _q = "UPDATE finance SET `invoice_no` = CONCAT('" + params.invoiceNo + "-', WEEK(_d)) , _ts_invoice = '" + moment(params.checkDate).add(1, "M").format("YYYY-MM-DD") + "', _ts_invoice_due = '" + moment(params.checkDate).add(2, "M").format("YYYY-MM-DD") + "' where 1=1 ";

    if (params.uuid) {
        _q += " and uuid = '" + params.uuid + "' ";
    }

    if (params.checkDate) {
        _q += " and month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear();
    }

    // _q += " group by WEEK(_d)";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function markVerified(params: InvoiceRequestParams) {
    // console.log(params);

    let _q = "UPDATE finance SET `verified` = " + params.verified + ", _ts_verify = '" + moment().format("YYYY-MM-DD") + "' where 1=1 ";

    if (params.uuid) {
        _q += " and uuid = '" + params.uuid + "' ";
    }

    if (params.checkDate) {
        _q += " and month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear();
    }

    // console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function performPaymentAPI(params: InvoiceRequestParams) {
    console.log(params);

    let _q = "UPDATE finance SET `payment`=`bill`, _ts_payment = '" + moment().format("YYYY-MM-DD") + "' where 1=1 ";

    if (params.uuid) {
        _q += " and uuid = '" + params.uuid + "' ";
    }

    if (params.checkDate) {
        _q += " and month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear();
    }

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getMonthlyInvoice(params: InvoiceRequestParams) {
    console.log(params);

    let _q = "SELECT  invoice_no, _ts_invoice, _d,  _ts_invoice_due, count(_d), p.rate, sum(f.hrs * p.rate) as _sbill, sum(payment) as _spayment, sum(hrs) as _shrs from finance as f left join project as p on f.uuid = p.uuid where f.uuid = '" + params.uuid + "' AND month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear() + " group by month(_d)";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getMonthlyItemizedInvoice(params: InvoiceRequestParams) {
    console.log(params);

    const startOfMonth = moment(params.checkDate).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(params.checkDate).endOf("month").format("YYYY-MM-DD");

    let _q = "with recursive all_dates(dt) as (select '" + startOfMonth + "' dt union all select dt + interval 1 day from all_dates where dt < '" + endOfMonth + "') ";

    _q +=
        "  SELECT invoice_no, _ts_invoice, _d,  _ts_invoice_due,  p.rate, max(p.rate) as maxrate, MIN(cd.dt) as first_day_of_week, MAX(cd.dt) as last_day_of_week, WEEK(_d, 5) _date, count(_d) as _count, sum(p.rate * ft.hrs) as _sbill, sum(payment) as _spayment, sum(paid) as _spaid, sum(verified), sum(hrs) as _shrs from all_dates cd left join finance ft on ft._d = cd.dt and uuid = '" +
        params.uuid +
        "' AND month(_d) = " +
        (params.checkDate.getMonth() + 1) +
        " and year(_d) = " +
        params.checkDate.getFullYear() +
        " left join project p on ft.uuid= p.uuid and dt between p.start_dt and p.end_dt left join project_master pm on pm.pid = p.pid group by WEEK(cd.dt, 5)";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
// method not used
export async function getMonthlyItemizedInvoiceOld(params: InvoiceRequestParams) {
    console.log(params);

    let _q = "SELECT invoice_no, _ts_invoice, _d,  _ts_invoice_due,  rate, MIN(_d) as first_day_of_week, MAX(_d) as last_day_of_week, WEEK(_d) _date, count(_d) as _count, sum(bill) as _sbill, sum(payment) as _spayment, sum(paid) as _spaid, sum(verified), sum(hrs) as _shrs from finance where uuid = '" + params.uuid + "' AND month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear() + " group by WEEK(_d)";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getWeeklyInvoice(params: InvoiceRequestParams) {
    console.log(params);

    let _q = "SELECT invoice_no, _ts_invoice, _d,  _ts_invoice_due,  project.rate, MIN(_d) as first_day_of_week, MAX(_d) as last_day_of_week, WEEK(_d) _date, count(_d) as _count, sum(project.rate * finance.hrs) as _sbill, sum(payment) as _spayment, sum(paid) as _spaid, sum(verified), sum(hrs) as _shrs from finance left join project on finance.uuid = project.uuid where finance.uuid = '" + params.uuid + "' AND month(_d) = " + (params.checkDate.getMonth() + 1) + " and year(_d) = " + params.checkDate.getFullYear() + " AND week(_d)=" + params.week + " group by WEEK(_d)";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
// // no need to change since finance contains pid
export async function getFinances(currMonth: any, curryear: any, uuid: any, pid: any, setIsLoading: any) {
    setIsLoading(true);
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "SELECT  hrs,DAY(_d) as day,WEEK(_d) as week, _d , uuid, pid, paid, rate, hrs, bill, payment, verified, name from finance where  MONTH(_d) = " + currMonth + " AND YEAR(_d) = " + curryear + " and uuid ='" + uuid + "' and pid = '" + pid + "'",
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
