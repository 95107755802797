import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import { timesheetBreadcrums } from "../../../constants/breadcrums";
import { blueBgClr, sectionClass, timesheetCommands } from "../../../constants/common";
import PageTitle from "../../../components/pageTitle";
import { listOfEmployees } from "../../../api/consultants-api";
import classNames from "classnames";
import CommonFilters from "../../../blocks/commonFilters";
import { bulkTimeSheetEntry, employeeTimeSheetHistory, fetchEmployeeTimSheetByDates, timesheetDetails } from "../../../api/timesheet-api";
import { TimesheetType } from "../../../model";
import { ColumnDef } from "@tanstack/react-table";
import TanstackTable from "../../../components/TanstackTable";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import Moment from "react-moment";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useSnackbar } from "react-simple-snackbar";
export default function BulkInsertResource() {
    const [isLoading, setLoading] = React.useState(false);
    const [isBulkLoading, setIsBulkLoading] = React.useState(false);
    const [people, setPeople] = useState([]);
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const [data, setData] = React.useState([]);
    const { register, handleSubmit, setValue, getValues } = useForm();
    const [isProjectSelect, setIsProjectSelect] = React.useState(false);
    const [projectSelect, setProjectSelect] = React.useState({ start_dt: "", end_dt: "", name: "", pid: "", hrs: 0, rate: 0 });
    const toUTCFormat = (d: any) => {
        // console.log(d);
        return moment.utc(moment(d)).format("MM/DD/YYYY");
        // return moment(d).add(1, "day").format();
    };
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    let [hrs, setHrs] = useState(8);
    const onAddTime = (data) => {
        setIsProjectSelect(true);
        setProjectSelect(data);
        setStartDate(moment(data.start_dt).toDate());
        setEndDate(moment(data.end_dt).toDate());
    };
    const onBack = () => {
        setIsProjectSelect(false);
    };
    const columns = React.useMemo<ColumnDef<any, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Project Name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                footer: ({ table }) => (
                    <>
                        <span className="font-semibold text-md">Total</span>
                    </>
                ),
            },

            {
                accessorKey: "start_dt",
                header: "Project period",
                cell: ({ row }) => (
                    <>
                        <Moment filter={toUTCFormat} local>
                            {row.original.start_dt}
                        </Moment>{" "}
                        -{" "}
                        <Moment filter={toUTCFormat} local>
                            {row.original.end_dt}
                        </Moment>
                    </>
                ),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    // cellClassName: "text-center",
                },
            },

            {
                accessorKey: "hrs",
                header: "Efforts (Hrs)",
                cell: (info) => Number(info.getValue()) | 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Efforts</span>
                        <br /> */}
                        <span className="text-md">{table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)}</span>
                    </>
                ),
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: ({ row }) => (
                    <a onClick={() => onAddTime(row.original)} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                        Add Time <span className="sr-only">, {row.original.name}</span>
                    </a>
                ),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
        ],
        []
    );

    useEffect(() => {
        console.log("Loading Statement ...");
        setLoading(true);

        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);
        setLoading(true);
        setData([]);
        setIsProjectSelect(false);
        employeeTimeSheetHistory(filters.employee)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    async function onSubmitTime() {
        setIsBulkLoading(true);
        if (!areDatesInRange(startDate, endDate, projectSelect.start_dt, projectSelect.end_dt)) {
            openSnackbar("Please enter valid date range");
        } else if (hrs < 0 && hrs > 24) {
            openSnackbar("Please enter valid hrs");
        } else {
            const dates = getWeekdaysBetweenDates(startDate, endDate);
            const selectedEmployee = people.find((e: any) => e.uuid === getValues().employee) as any;
            console.log(selectedEmployee);
            const data = await fetchEmployeeTimSheetByDates(selectedEmployee?.uuid, projectSelect?.pid, startDate, endDate);
            console.log(data);
            let bulkFinances: any[] = [];
            let bulkUpdate: any[] = [];
            dates.map((date: Date) => {
                let updatedData = data.find((f: any) => date.getFullYear() === new Date(f._d).getFullYear() && date.getDate() === new Date(f._d).getDate() && date.getMonth() === new Date(f._d).getMonth());

                console.log(updatedData);
                if (updatedData && updatedData.uuid) {
                    updatedData._ts_bill = moment(updatedData.bill).format("YYYY-MM-DD");

                    updatedData.hrs = hrs;

                    updatedData._d = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(); //"2023-07-01",

                    updatedData._ts_invoice = moment(updatedData._ts_invoice).format("YYYY-MM-DD");

                    updatedData._ts_invoice_due = moment(updatedData._ts_invoice_due).format("YYYY-MM-DD");

                    updatedData._ts_payment = moment(updatedData._ts_payment).format("YYYY-MM-DD");

                    updatedData._ts_verify = moment(updatedData._ts_verify).format("YYYY-MM-DD");

                    bulkUpdate.push(updatedData);
                } else {
                    const finance = {
                        uuid: selectedEmployee?.uuid,
                        pid: projectSelect?.pid,
                        _d: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(), //"2023-07-01",
                        // _ts_invoice: moment().format("YYYY-MM-DD"),
                        // _ts_invoice_due: moment().add(1, "M").format("YYYY-MM-DD"),
                        // _ts_payment: null,
                        // _ts_verify: null,

                        paid: 0,

                        hrs: hrs,
                        payment: 0,
                        verified: true,
                    };
                    bulkFinances.push(finance);
                }
            });
            bulkTimeSheetEntry(bulkFinances, bulkUpdate)
                .then((data) => {
                    openSnackbar("Time Added Successfully");
                    setIsProjectSelect(false);
                    applyFilters(getValues());
                })
                .catch((error: any) => {
                    openSnackbar("Time cannot be added.");
                })
                .finally(() => {
                    setIsBulkLoading(false);
                });
        }
    }

    function areDatesInRange(sdate: string | Date, edate: string | Date, rangeStartDate: string | Date, rangeEndDate: string | Date): boolean {
        // Convert all dates to Date objects to ensure comparison works correctly
        const start = new Date(rangeStartDate);
        const end = new Date(rangeEndDate);
        const userStart = new Date(sdate);
        const userEnd = new Date(edate);

        // Check if the user-entered dates are within the given range (inclusive)
        return userStart >= start && userEnd <= end && userStart <= userEnd;
    }

    function getWeekdaysBetweenDates(startDate: Date, endDate: Date): Date[] {
        let currentDate = new Date(startDate);
        let weekdays: Date[] = [];

        // Ensure the end date is greater than or equal to start date
        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)

            // If it's not Saturday (6) or Sunday (0), add the date to the array
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                weekdays.push(new Date(currentDate)); // Push a copy of the current date
            }

            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return weekdays;
    }
    return (
        <div>
            <div>
                <main>
                    <Breadcrums breadcrumLinks={timesheetBreadcrums} commands={timesheetCommands} module={"Timesheet"} groups={["Statement"]} />
                    <PageTitle title={"Resourcewise Timesheet Statement"} preSummary={"Organizational Overview"} summary={"Overview of monthly total of individual employee."}></PageTitle>

                    <div className={classNames(sectionClass)}>
                        <div className="pt-0 space-y-6">
                            <form onSubmit={handleSubmit(applyFilters)}>
                                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                    <div className="md:grid md:grid-cols-2 md:gap-6">
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-1">
                                                    <label htmlFor="employee" className="block text-sm font-medium text-gray-700">
                                                        Employee
                                                    </label>
                                                    <select {...register("employee", { required: true })} id="employee" multiple={false} size={1} name="employee" className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                        <option value="" selected>
                                                            Please select
                                                        </option>
                                                        {people.map((p: any, pIdx: any) => (
                                                            <option value={p.uuid} selected={getValues().employee === p.uuid} key={nanoid()}>
                                                                {p.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="pt-6">
                                                    <button type="submit" className={classNames("px-4 py-2 text-sm font-medium text-white", blueBgClr)}>
                                                        Apply
                                                    </button>
                                                    {/* &nbsp;
                                                <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Reset
                                                </button>{" "} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {!isProjectSelect && <div>{data && data.length > 0 && <TanstackTable columns={columns} data={data} downloadBtn={true}></TanstackTable>}</div>}
                        {isProjectSelect && (
                            <div className="pt-5 space-y-6">
                                <button type="button" onClick={() => onBack()} className={classNames("px-4 py-2 text-sm font-medium text-white", blueBgClr)}>
                                    back
                                </button>
                                <section className="bg-white shadow">
                                    <div className="px-4 py-6 sm:px-6 lg:px-8">
                                        <div className="sm:flex sm:items-center">
                                            <div className="sm:flex-auto">
                                                <h1 className="text-2xl font-semibold text-gray-900">{projectSelect && projectSelect?.name}</h1>
                                            </div>
                                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                <p className="mt-2 text-sm text-gray-700">project Duration</p>
                                                <Moment filter={toUTCFormat} local>
                                                    {projectSelect && projectSelect.start_dt}
                                                </Moment>{" "}
                                                -{" "}
                                                <Moment filter={toUTCFormat} local>
                                                    {projectSelect && projectSelect.end_dt}
                                                </Moment>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-6 sm:px-6 lg:px-8">
                                        <div className="grid grid-cols-4 gap-6">
                                            <div className="col-span-4 sm:col-span-4 lg:col-span-1">
                                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                    Start Date
                                                </label>

                                                <DatePicker
                                                    {...register("start_dt", {
                                                        required: false,
                                                    })}
                                                    // value={props.profile.start_dt}
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        console.warn(date);
                                                        setStartDate(date);
                                                    }}
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>

                                            <div className="col-span-4 sm:col-span-4 lg:col-span-1">
                                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                    End Date
                                                </label>

                                                <DatePicker
                                                    // value={props.profile.end_dt}
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={endDate}
                                                    onChange={(date) => {
                                                        console.warn(date);
                                                        setEndDate(date);
                                                    }}
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-4 sm:col-span-4 lg:col-span-1">
                                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                    Hrs / Day
                                                </label>

                                                <input
                                                    type="number"
                                                    value={hrs}
                                                    onChange={(e) => {
                                                        const hr = Number(e.target.value);
                                                        setHrs(hr);
                                                    }}
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-4 sm:col-span-4 lg:col-span-1 pt-6">
                                                {isBulkLoading && (
                                                    <div role="status">
                                                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path
                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                fill="currentFill"
                                                            />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )}
                                                {!isBulkLoading && 
                                                (<button type="button" disabled={isLoading} onClick={() => onSubmitTime()} className={classNames("px-4 py-2 text-sm font-medium text-white", blueBgClr)}>
                                                    Add Time
                                                </button>)
}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
}
