import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { addUpdateConsultant } from "../api/consultants-api";
import { Md5 } from "ts-md5";
import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import moment from "moment";

export default function EmployeeDetailsForm(props: { profile: any }) {
    const { register, handleSubmit, reset } = useForm();
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const format2 = "YYYY-MM-DD"
    const navigate = useNavigate();

    if (props.profile.dob) {
        props.profile.dob = moment.utc(props.profile.dob.toLocaleString()).format("YYYY-MM-DD");
    }

    useEffect(() => {
        // you can do async server request and fill up form
        console.log(props.profile);
        reset(props.profile);
    }, [reset]);

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        if (props.profile?.uuid) {
            const consultantData = {
                uuid: props.profile.uuid,
                active: props.profile.active,
                bgv: props.profile.bgv,
                dob: moment(data?.dob).format(format2),
                email: data.email,
                masked_ssn: data.masked_ssn,

               

                name: data.name,
                phone: data.phone,


                status: props.profile.status,
                bgv_by: props.profile.bgv_by,
                bgv_dt: props.profile.bgv_dt,

                street: data.street,
                city: data.city,
                district: data.district,
                state: data.state,
                zip: data.zip,
                country: data.country,
                
            };

            addUpdateConsultant(consultantData).then((result: any) => {
                console.log(result);
                openSnackbar(result?.error ? "Unable to save Consultant" : "Consultant Saved.");
            }).catch((error:any)=>{
                openSnackbar("Unable to save Consultant");
            });
        } else {
            const consultantData = {
                uuid: Md5.hashStr(data.email),
                active: data?.active,
                bgv: data?.bgv,
                dob: data?.dob,
                email: data?.email,

                
                masked_ssn: data?.masked_ssn,

                name: data?.name,
                phone: data?.phone,
                
                status: data?.status,
                
                bgv_by: data?.bgv_by,
                bgv_dt: data?.bgv_dt,

                street: data.street,
                city: data.city,
                district: data.district,
                state: data.state,
                zip: data.zip,
                country: data.country,
            };

            addUpdateConsultant(consultantData).then((result: any) => {
                console.log(result);
                if (result) {
                    openSnackbar(result.error ? result.error.sqlMessage : "Consultant Saved.");
                } else openSnackbar("Some Error!");
            });
        }
    };
    return (
        <div className="space-y-6">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-xs text-gray-500">Legal Details</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="emp-name" className="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <input {...register("name")} type="text" name="name" id="name" autoComplete="name" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Date of Birth
                                    </label>
                                    <input {...register("dob")} type="text" name="dob" id="dob" autoComplete="dob" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    {/* <DatePicker {...register("dob")} dateFormat="yyyy-MM-dd" selected={props.profile.dob} onChange={(date) => {}} /> */}
                                    <span className="text-xs text-gray-400">yyyy-mm-dd</span>
                                </div>

                                {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <input type="text" disabled readOnly name="email-address" id="email-address" autoComplete="email" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Information</h3>
                            <p className="mt-1 text-xs text-gray-500">Phone, Email etc.</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                        Phone
                                    </label>
                                    <input {...register("phone", { required: false })} type="text" name="phone" id="phone" autoComplete="email" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <input {...register("email", { required: true })} type="text" name="email" id="email" autoComplete="email" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Legal Information</h3>
                            <p className="mt-1 text-xs text-gray-500">SSN etc.</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label htmlFor="masked_ssn" className="block text-sm font-medium text-gray-700">
                                        Social Security Number <small>(masked)</small>
                                    </label>
                                    <input {...register("masked_ssn", {})} type="text" name="masked_ssn" id="masked_ssn" autoComplete="masked_ssn" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Permenant Address Information</h3>
                            <p className="mt-1 text-xs text-gray-500">Use a permanent address where you can receive mail.</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                                        Street address
                                    </label>
                                    <input {...register("street", {})} type="text" name="street" id="street" autoComplete="street-address" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        City
                                    </label>
                                    <input {...register("city", {})} type="text" name="city" id="city" autoComplete="city" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                        State / Province
                                    </label>
                                    <input {...register("state", {})} type="text" name="state" id="state" autoComplete="state" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                        ZIP / Postal code
                                    </label>
                                    <input {...register("zip", {})} type="text" name="zip" id="zip" autoComplete="postal-code" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                        Country
                                    </label>
                                    <select {...register("country", {})} id="country" name="country" autoComplete="country-name" className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>Mexico</option>
                                        <option>India</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
                        <p className="mt-1 text-sm text-gray-500">Decide which communications you'd like to receive and how.</p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form className="space-y-6" action="#" method="POST">
                            <fieldset>
                                <legend className="text-base font-medium text-gray-900">By Email</legend>
                                <div className="mt-4 space-y-4">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="comments" name="comments" type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="comments" className="font-medium text-gray-700">
                                                Comments
                                            </label>
                                            <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="candidates" name="candidates" type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="candidates" className="font-medium text-gray-700">
                                                Candidates
                                            </label>
                                            <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                        </div>
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="offers" name="offers" type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="offers" className="font-medium text-gray-700">
                                                Offers
                                            </label>
                                            <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div>
                                    <legend className="text-base font-medium text-gray-900">Push Notifications</legend>
                                    <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                                </div>
                                <div className="mt-4 space-y-4">
                                    <div className="flex items-center">
                                        <input id="push-everything" name="push-notifications" type="radio" className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                                        <label htmlFor="push-everything" className="block ml-3 text-sm font-medium text-gray-700">
                                            Everything
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input id="push-email" name="push-notifications" type="radio" className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                                        <label htmlFor="push-email" className="block ml-3 text-sm font-medium text-gray-700">
                                            Same as email
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input id="push-nothing" name="push-notifications" type="radio" className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                                        <label htmlFor="push-nothing" className="block ml-3 text-sm font-medium text-gray-700">
                                            No push notifications
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div> */}

                <div className="flex justify-end pt-5 pb-10">
                    <Link to="/employees">
                        <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Cancel
                        </button>
                    </Link>

                    <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Save
                    </button>
                </div>
            </form>
            {/* <div className="pt-2 space-y-6">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm text-gray-700 font-sm">
                                        Month-Year
                                    </label>
                                    <DatePicker dateFormat="MM/yyyy" showMonthYearPicker className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" selected={filters.checkDate} onChange={(date) => setFilters({ ...filters, checkDate: date })} />
                                </div>

                                <div className="pt-6">
                                    <button type="button" onClick={(e) => console.log()} className="px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 hover:bg-blue-800 focus:ring-blue-300">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div> */}
        </div>
        
    );
}
