import moment from "moment";
// need to change
export async function timesheetDetails(filters: any) {
    // console.log(filters);

    console.log(process.env.REACT_APP_API_URL);

    // IF checkDate

    // const _q = "select finance.uuid, consultant.name,  month(_d), sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid and  year(_d) = " + filters.checkDate.getFullYear() + " and month(_d) = " + (filters.checkDate.getMonth() + 1) + " group by uuid, name, month(_d) order by name";

    let _q = "select finance.uuid, consultant.name, finance.pid,  month(_d) as _m, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%M') AS month, sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid ";

    if (filters && filters.checkDate) {
        _q += "  and month(_d) = " + (filters.checkDate.getMonth() + 1) + "";
        _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
    }

    if (filters && filters.employee) {
        _q += "  and finance.uuid = '" + filters.employee + "' ";
    }

    _q += " group by uuid, name, month1, year order by name, _d";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function timesheetStatementByMonth(filters: any) {
    console.log(filters);

    let _q = "select finance.uuid, consultant.name,  month(_d) as _m, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%Y-%M') AS month, sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid ";

    if (filters && filters.checkDate) {
        _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
    }

    if (filters && filters.employee) {
        _q += "  and finance.uuid = '" + filters.employee + "' ";
    }

    _q += " group by _m order by _m";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function employeeTimeSheetHistory(uuid:any){
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "select IFNULL(sum(finance.hrs),0) as hrs,project.rate,project.start_dt,project.end_dt,pm.name,project.pid from  project  left JOIN finance on finance.uuid = project.uuid AND finance.pid= project.pid AND _d BETWEEN project.start_dt AND project.end_dt   left join project_master as pm on pm.pid = project.pid    where project.uuid = '" + uuid + "' group by project.pid",
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
// need to change
export async function fetchEmployeeTimSheetByDates(uuid:any,pid:any,startDate:Date,endDate:Date){
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "select * from finance where uuid = '" + uuid + "'and pid = '"+pid+"' and _d between '"+moment.utc(startDate).format("YYYY/MM/DD")+"' AND '"+moment.utc(endDate).format("YYYY/MM/DD") + "'",
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function bulkTimeSheetEntry(data:any[],bulkUpdate:any[]){
    try {
        if(data.length > 0){
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/finance/bulk", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
    }
        // Create an array of fetch requests based on the `urls` array
    const fetchPromises = bulkUpdate.map(url => 
        fetch(process.env.REACT_APP_API_URL + "/api/finance", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(url)
        })
    );

    // Wait for all requests to complete
    const responses = await Promise.all(fetchPromises);

    // Wait for all responses to be converted to JSON
    const apiResponse = await Promise.all(responses.map(res => res.json()));
        return await apiResponse;
    } catch (error) {
        return [];
    }
}