import React, { useEffect, useState } from "react";
import Breadcrums from "../components/breadcrums";
import Moment from "react-moment";
import { timesheetDetails } from "../api/timesheet-api";
import { nanoid } from "nanoid";
import TimesheetFilters from "../blocks/timesheetFilters";
import classNames from "classnames";

import Modal from "../components/modal";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../components/pageTitle";
import { timesheetBreadcrums } from "../constants/breadcrums";
import { sectionClass, timesheetCommands } from "../constants/common";
import TanstackTable from "../components/TanstackTable";
import { ColumnDef } from "@tanstack/react-table";
import { TimesheetType, consultant } from "../model";
import { Link } from "react-router-dom";

export default function Timesheet() {
    const [timesheetdata, setTimesheetData] = useState([]);

    const [isLoading, setLoading] = React.useState(false);

    const [filteredCheckDate, setFilteredCheckDate] = useState(new Date());

    useEffect(() => {
        setLoading(true);
        setTimesheetData([]);

        timesheetDetails({
            checkDate: new Date(),
        })
            .then((data) => {
               console.log(data) 
                setTimesheetData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        setLoading(true);
        setTimesheetData([]);

        console.log(filters);
        timesheetDetails(filters)
            .then((data) => {
                console.log(data);
                setTimesheetData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = React.useMemo<ColumnDef<TimesheetType, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                // footer: ({ table }) => (
                //     <>
                //         <span className="font-semibold text-md">Total</span>
                //     </>
                // ),
            },

            {
                accessorKey: "pid",
                header: "Project",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                // footer: ({ table }) => (
                //     <>
                //         <span className="font-semibold text-md">Total</span>
                //     </>
                // ),
            },

            {
                accessorKey: "totalHrs",
                header: `Total Efforts <small class='text-xs text-gray-500 font-extralight'>(Hrs)</small>`,
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                // footer: ({ table }) => (
                //     <>
                //         {/* <span className="text-xs font-normal">Total Efforts</span>
                //         <br /> */}
                //         <span className="text-md">{table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.totalHrs, 0)}</span>
                //     </>
                // ),
            },
            // {
            //     accessorKey: "uuid",
            //     header: "",
            //     cell: ({ row }) => (
            //         <a href={`/timesheet/${row.original.uuid}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
            //             Details<span className="sr-only">, {row.original.name}</span>
            //         </a>
            //         // <Modal title={"Timesheet Report"}>
            //         //     <p className="text-sm text-gray-500">Coming soon</p>
            //         // </Modal>
            //     ),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            // },
        ],
        []
    );
    function addTimesheet(): any {
            }
    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={timesheetBreadcrums} commands={timesheetCommands} module={"Timesheet"} groups={["Statement"]} />
                <PageTitle title={"Timesheet"} preSummary={"Organizational Overview"} summary={"Overview of monthly total of individual employee."}></PageTitle>

                <div className={classNames(sectionClass)}>
                    <TimesheetFilters applyFilters={applyFilters} setFilteredCheckDate={setFilteredCheckDate}></TimesheetFilters>
                    <div className="pt-6">
                    <Link to="/timesheet/bulkInsert">
                                    <button type="button" onClick={(e) => addTimesheet()} className="px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 hover:bg-blue-800 focus:ring-blue-300">
                                        Add TimeSheet
                                    </button>
                    </Link>
                                </div>
                    {timesheetdata.length > 0 && <TanstackTable data={timesheetdata} columns={columns} downloadBtn={true}></TanstackTable>}
                </div>
            </main>
        </>
    );
}
