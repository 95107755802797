export async function GetClients() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/client_master");
        return await response.json();
    } catch (error) {
        return [];
    }   
}
export async function GetProjectByClient(id:any) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/project_master?_where=(cid,eq,"+id+")");
        return await response.json();
    } catch (error) {
        return [];
    }   
}
export async function AddClient(data) {
    try {
        
    
    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "/api/client_master", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    const content = await rawResponse.json();
    return content;
} catch (error) {
    return [];
}
}
export async function AddProjectMaster(data) {
    try {
        
    
    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "/api/project_master", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    const content = await rawResponse.json();
    return content;
} catch (error) {
    return [];
}
}
export async function getAllProjects() {
    
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "select *,cm.name as clientName from client_master as cm right join project_master as pm on cm.id= pm.cid",
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
export async function getAllProjectsByUuid(uuid) {
    
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: "select * from project_master as pm left join project as p on pm.pid = p.pid where p.uuid = '"+uuid+"' order by p.active desc",
              params: ["payroll"],
            }),
          });
          
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function AddProject(data) {
    try {
        
    
    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "/api/project", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    const content = await rawResponse.json();
    return content;
} catch (error) {
    return [];
}
}