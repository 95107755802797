/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import { Md5 } from "ts-md5";
import { AddProjectMaster } from "../api/project-api";
const team = [
    {
        name: "Tom Cook",
        email: "tom.cook@example.com",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
        name: "Whitney Francis",
        email: "whitney.francis@example.com",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
        name: "Leonard Krasner",
        email: "leonard.krasner@example.com",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
        name: "Floyd Miles",
        email: "floy.dmiles@example.com",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
        name: "Emily Selman",
        email: "emily.selman@example.com",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
];

const ProjectDrawer = (props: { open: any; onClose: any ,cid:any}) => {
    const { open,cid } = props;
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data:any) => {
        if(data.name !== ''){
        const project_master  = {
            cid:cid,
            pid:Md5.hashStr(data.name),
            name:data.name,
            payment_terms:data['payment_terms'],
            active:1
        }
        AddProjectMaster(project_master).then((data:any)=>{
        })
    }
    }
    const dialogClose = () => {
        props.onClose()
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={dialogClose}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
                        <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                            <div className="w-screen max-w-md pointer-events-auto" onSubmit={handleSubmit(onSubmit)}>
                                <form className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="px-4 py-6 bg-indigo-700 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">  Project </Dialog.Title>
                                                <div className="flex items-center ml-3 h-7">
                                                    <button type="button" className="text-indigo-200 bg-indigo-700 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={dialogClose}>
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="w-6 h-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-indigo-300">Get started by filling in the information below to create your new project.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-between flex-1">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                <div className="pt-6 pb-5 space-y-6">
                                                    <div>
                                                        <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                                                            {" "}
                                                            Project name{" "}
                                                        </label>
                                                        <div className="mt-1">
                                                            <input type="text" {...register("name")} name="name" id="project-name" className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                                            {" "}
                                                            Payment Terms{" "}
                                                        </label>
                                                        <div className="mt-1">
                                                            <input id="description" {...register("payment_terms")}name="payment_terms" type="text" className="block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                        </div>
                                                    </div>
                                                   
                                                   
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end flex-shrink-0 px-4 py-4">
                                        <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={dialogClose}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ProjectDrawer;
