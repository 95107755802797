import { useEffect, useState } from "react";
import ProjectTable from "../blocks/projectTable";
import Breadcrums from "../components/breadcrums";
import { GetClients } from "../api/project-api";
import ClientTable from "../blocks/clientTable";

const breadcrumLinks = [{ name: "Clients", href: "/clients", current: true }];

export default function Client() {
    const [client, setClient] = useState<any[]>([]);

   

    useEffect(() => {
        if(client.length === 0){
       GetClients().then((data:any)=>{
        setClient(data)
       })
    }
    }, [client]);
    return (
        <>
            <main className="">
                {/* <PageHeader breadcrumLinks={breadcrumLinks} actions={actionLinks} title={"Projects"} summary={"Project Portfolio Snapshot"} summaryLine={"Overview of Ongoing and Completed Initiatives."} /> */}

                <div className="px-4 pt-5 mx-auto sm:px-6 lg:px-8">
                    <Breadcrums breadcrumLinks={breadcrumLinks} module={"Projects"} />
                </div>

                <div className="px-4 py-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                    <div className="flex-1 min-w-0">
                        <h1 className="text-2xl font-semibold text-gray-900">Clients/Projects</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            <b>Project Portfolio Snapshot</b> - Overview of Ongoing and Completed Initiatives.
                        </p>
                    </div>
                    <div className="flex mt-4 sm:mt-0 sm:ml-4">
                        
                        <button type="button" className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm order-0 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3">
                            Create
                        </button>
                    </div>
                </div>

                <section className="px-4 py-5 sm:p-6">
                    {/* <ProjectList /> */}
                    {client && <ClientTable clients={client} />}
                </section>
            </main>
        </>
    );
}
