import React, { createContext, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Employees from "./pages/employees";
import Dashboard from "./pages/dashboard";
import Employee from "./pages/employee";
import Projects from "./pages/projects";
import Settings from "./pages/settings";
import Directory from "./pages/directory";

import { useAuth0 } from "@auth0/auth0-react";
import Landing from "./landing";
import UserContext from "./components/TheUser";
import DefaultLayout from "./layout/default";
import Login from "./login";
import NotFound from "./404";

import { useIdleTimer } from 'react-idle-timer';


import { ConvexProvider, ConvexReactClient } from "convex/react";
import Tasks from "./pages/tasks";
import NewEmployee from "./pages/newEmployee";
import Profile from "./pages/profile";
import Reports from "./pages/reports";
import Payments from "./pages/payments";
import FinanceReport from "./pages/report/financeReport";
import Timesheet from "./pages/timesheet";
import Payroll from "./pages/payroll";
import NewPayroll from "./pages/newPayroll";
import TheEmployee from "./pages/theEmployee";
import Test from "./pages/test";
import AccountReceivableByUser from "./pages/report/payments/account-receivable-by-user";
import AccountReceivableByMonth from "./pages/report/payments/account-receivable-by-month";
import AccountReceivableIkat from "./pages/report/payments/account-receivable-ikat";
import OutstandingPayments from "./pages/report/outstanding-payments";
import YTDStatement from "./pages/report/payroll/ytd-statement";
import PayrollDetails from "./pages/report/payroll/payroll-details";
import PayrollSummary from "./pages/report/payroll/payroll-summary";
import MonthlySnapshotReport from "./pages/report/payroll/monthly-snapshort-report";
import Invoices from "./pages/report/payments/invoices";
import Invoice from "./pages/report/payments/invoice";
import BulkPayments from "./pages/report/payments/bulk";
import AdjustPayments from "./pages/report/payments/adjust";
import TimesheetDetails from "./pages/timesheetDetails";
import PayrollItem from "./pages/payrollItem";
import ImportPayroll from "./pages/importPayroll";
import TimesheetResourcsewise from "./pages/report/timesheet/timesheetResourcsewise";
import TimesheetMonthwise from "./pages/report/timesheet/timesheetMonthwise";
import BulkInsertResource from "./pages/report/timesheet/bulkInsertResource";
import Client from "./pages/client";
import ProtectedRoute from "./ProtectedRoute";

function App() {
    const { isAuthenticated, user, loginWithRedirect, logout, loginWithPopup } = useAuth0();

    const convex = new ConvexReactClient(process.env.REACT_APP_CONVEX_URL as string);



  const onIdle = () => {
    console.log('User is idle. Logging out...');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minutes in milliseconds
    onIdle,
    debounce: 500, // Optional debounce to avoid rapid events
  });

    return (
        <>
            <ConvexProvider client={convex}>
                <UserContext.Provider value={{ user: user }}>
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/landing" element={<Landing />} />
                        {/* <Route path="*" element={<PageNotFound />} /> */}
                        {/* <Route path="/login" element={<Login />} /> */}

                        <Route element={<DefaultLayout />}>
                            <Route path="/home" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

                            <Route path="/employees" element={<ProtectedRoute><Employees /></ProtectedRoute>} />
                            <Route path="/employees/:handle" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
                            <Route path="/employee/:handle" element={<ProtectedRoute><TheEmployee /></ProtectedRoute>} />
                            <Route path="/employees/new"   element={<ProtectedRoute><NewEmployee /></ProtectedRoute>} />
                            <Route path="/clients"  element={<ProtectedRoute><Client /></ProtectedRoute>} />
                            <Route path="/projects/:cid"  element={<ProtectedRoute><Projects /></ProtectedRoute>} />
                            <Route path="/projects"  element={<ProtectedRoute><Projects /></ProtectedRoute>} />
                            <Route path="/settings"  element={<ProtectedRoute><Settings /></ProtectedRoute>} />

                            <Route path="/tasks"  element={<ProtectedRoute><Tasks /></ProtectedRoute>} />

                            <Route path="/payments"  element={<ProtectedRoute><Payments /></ProtectedRoute>} />
                            <Route path="/payments/receivable/user"  element={<ProtectedRoute><AccountReceivableByUser /></ProtectedRoute>} />
                            <Route path="/payments/receivable/month"  element={<ProtectedRoute><AccountReceivableByMonth /></ProtectedRoute>} />
                            <Route path="/payments/receivable/ikat"  element={<ProtectedRoute><AccountReceivableIkat /></ProtectedRoute>} />

                            <Route path="/payments/outstanding"  element={<ProtectedRoute><OutstandingPayments /></ProtectedRoute>} />

                            <Route path="/payments/invoice"  element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
                            <Route path="/payments/invoice/:uuid/:_m/:_y/:_invNo/:_d"  element={<ProtectedRoute><Invoice /></ProtectedRoute>} />
                            <Route path="/payments/invoice/:uuid/:_w/:_m/:_y/:_invNo/:_d"  element={<ProtectedRoute><Invoice /></ProtectedRoute>} />

                            <Route path="/payments/bulk"  element={<ProtectedRoute><BulkPayments /></ProtectedRoute>} />
                            <Route path="/payments/adjust"  element={<ProtectedRoute><AdjustPayments /></ProtectedRoute>} />

                            <Route path="/test"  element={<ProtectedRoute><Test /></ProtectedRoute>} />

                            <Route path="/reports"  element={<ProtectedRoute><Reports /></ProtectedRoute>} />
                            <Route path="/reports/finance"  element={<ProtectedRoute><FinanceReport /></ProtectedRoute>} />

                            <Route path="/profile"  element={<ProtectedRoute><Profile /></ProtectedRoute>} />

                            <Route path="/payroll"  element={<ProtectedRoute><Payroll /></ProtectedRoute>} />
                            <Route path="/payroll/new"  element={<ProtectedRoute><NewPayroll /></ProtectedRoute>} />
                            <Route path="/payroll/ytd"  element={<ProtectedRoute><YTDStatement /></ProtectedRoute>} />
                            <Route path="/payroll/month"  element={<ProtectedRoute><MonthlySnapshotReport /></ProtectedRoute>} />
                            <Route path="/payroll/summary"  element={<ProtectedRoute><PayrollSummary /></ProtectedRoute>} />
                            <Route path="/payroll/detail"  element={<ProtectedRoute><PayrollDetails /></ProtectedRoute>} />
                            <Route path="/payroll/:uuid"  element={<ProtectedRoute><PayrollItem /></ProtectedRoute>} />
                            <Route path="/payroll/import"  element={<ProtectedRoute><ImportPayroll /></ProtectedRoute>} />

                            <Route path="/timesheet"  element={<ProtectedRoute><Timesheet /></ProtectedRoute>} />
                            <Route path="/timesheet/statement/user"  element={<ProtectedRoute><TimesheetResourcsewise /></ProtectedRoute>} />
                            <Route path="/timesheet/statement/month"  element={<ProtectedRoute><TimesheetMonthwise /></ProtectedRoute>} />
                            <Route path="/timesheet/:uuid"  element={<ProtectedRoute><TimesheetDetails /></ProtectedRoute>} />
                            <Route path="/timesheet/bulkInsert"  element={<ProtectedRoute><BulkInsertResource /></ProtectedRoute>} />

                            {/* <Route path="/timesheet" element={<Timesheet />} /> */}
                            {/* <Route path="/directory" element={<Directory />} /> */}
                        </Route>

                        <Route path="*" element={<NotFound />} />

                        {/* 
                            {/* <Route path="/information" element={<Information />} />
                            <Route path="/profile" element={<Profile />} />
                    */}
                    </Routes>
                </UserContext.Provider>
            </ConvexProvider>
        </>
    );
}

export default App;


